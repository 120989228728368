import React from 'react'
const TextInput = ctx => {
  return (
    <input
      className={
        ctx.validationErrors.filter(e => e.path.indexOf(ctx.name.toLowerCase()) !== -1).length > 0
          ? 'is-danger input'
          : 'input'
      }
      value={ctx.value}
      onChange={ctx.onChange}
      id={ctx.name}
      name={ctx.name}
      placeholder={ctx.placeholder}
      type={ctx.type || 'text'}
    />
  )
}

export default TextInput
