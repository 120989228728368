import React from 'react'
const ValidationSummary = ({ validationErrors }) => {
  return validationErrors && validationErrors.length > 0 ? (
    <div className="valiation-summary has-light has-background-danger has-text-white">
      <ul>
        {validationErrors.map(err => {
          return <li key={err.message}>{err.message}</li>
        })}
      </ul>

      <style jsx>{`
        .valiation-summary {
          padding: 1em;
          margin-bottom: 1em;
          border-radius: 4px;
        }
      `}</style>
    </div>
  ) : null
}

export default ValidationSummary
